::-webkit-scrollbar {
  width: 9px;

  &-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.3);
    background-color: #999;

    &:hover {
      background-color: #777;
    }
  }

  &-track {
    display: none;
  }
}
