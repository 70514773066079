.coHistorie {
  &__accordion {
    box-shadow: none !important;
    background-color: $tableContainerBackgroundColor !important;
    padding-top: 0 !important;
  }

  &__dialogTableRowTitle {
    width: 70% !important;
  }

  &__lastTableCell {
    border-bottom: none !important;
  }

  &__searchTextField {
    margin-left: 10px !important;
  }

  &__tableContainer {
    border-top: none;
    border-bottom: none !important;
    background-color: $accordionBackgroundColor !important;
  }

  &__tableHead {
    .table__rowTitle {
      background-color: $accordionBackgroundColor !important;
    }
  }
}
