// colors
$color-black: rgba(0, 0, 0, 0.87);
$color-white: rgba(255, 255, 255, 1);
$color-main: rgba(76, 175, 80, 1);
$color-Iframe-main: #e3000f;

// buttons -> style
$buttonColorGreen: $color-main;
$buttonColorRed: rgb(244, 67, 54);
$buttonOnHoverGreen: rgb(73, 168, 77);
$buttonOnHoverRed: rgb(219, 59, 48);
$buttonColorGreenTransparent: rgba(76, 175, 80, 0.5);
$buttonColorRedTransparent: rgba(244, 67, 54, 0.5);

// form -> style
$formSelectColorRed: rgb(219, 59, 48);
$formSelectColorOrange: rgb(255, 136, 0);
$formSelectColorGreen: $color-main;

// icon -> style
$iconColorGreen: $color-main;
$iconColorRed: rgb(244, 67, 54);

// shadow ->
$shadowOnHoverColor: rgba(189, 189, 189, 1);

// trackingIcons -> style
$trackinIconDone: $color-main;
$trackinIconInProgress: rgba(189, 189, 189, 1);

// typography -> style
$typographyColorGreen: rgba(76, 175, 80, 1);
$typographyColorRed: rgba(244, 67, 54, 1);
$typographyHeaderTitleColor: $color-black;
$typographyFormTitle: $color-black;
$typographySingleTableTitle: $color-black;
$typographySingleTableRowTitle: $color-black;
$typographySingleTableRowContent: $color-black;
$typographyTimeline: $color-black;
$typographyTimelineOpposite: rgba(189, 189, 189, 1);
$typographyMessage: $color-black;
$typographyBackGroundColor: rgba(189, 189, 189, 1);
$typographyColorGrey: grey;

// table -> style
$tableTitleBackgroundColor: rgba(242, 242, 242, 1);
$tableMoreBackgroundColor: $color-main;
$tableContainerBackgroundColor: rgba(250, 250, 250, 1);
$tableRowHoverColor: rgb(224, 224, 224);
$tableRowActiveColor: rgb(210, 210, 210);

// timeline -> style
$colorTimelineStartBackgroundColor: rgba(76, 175, 80, 1);
$colorTimelineEndBackgroundColor: rgba(244, 64, 52, 1);

// topNav -> style
$topNavTextEnabledColor: $color-white;
$topNavTextDisabledColor: rgba(255, 255, 255, 0.5);
$topNavLinksColor: $color-white;
$topNavLinksTextShadowColor: $color-black;

$topNavBackgroundColor: rgb(32, 106, 93);
$topNavInIframeBackgroundColor: rgb(170, 9, 9);
$topNavAccordionBackgroundColor: rgba(32, 106, 93, 0.9);
$topNavAccordionInIframeBackgroundColor: $topNavInIframeBackgroundColor;

$topNavItemActiveColor: rgb(115, 231, 91);
$topNavItemActiveInIframeColor: rgb(255, 93, 93);

// other colors
$borderColor: rgb(224, 224, 224);
$accordionBackgroundColor: rgba(242, 242, 242, 1);
$accordionSummaryHoverColor: rgb(224, 224, 224);
$listItemHoverColor: rgb(224, 224, 224);
