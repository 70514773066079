.coArtikel {
  &__tableContainer {
    border-top: none;
    border-bottom: none !important;
    background-color: $tableTitleBackgroundColor !important;
    max-height: 650px !important;

    &--border {
      border-top: none;
      background-color: $tableTitleBackgroundColor !important;
      max-height: 650px !important;
    }
  }
}
