.coVersandavisierung {
  &__anhangListItem {
    cursor: pointer;
    transition: all 0.3s;
    padding-left: 0 !important;
    &:hover {
      background-color: $listItemHoverColor !important;
      padding-left: 10px !important;
    }
  }

  &__tableContainer {
    height: 100px !important;
    background-color: $tableContainerBackgroundColor !important;
    border-top: none !important;
  }

  &__tableCell {
    cursor: pointer;
    transition: all 0.3s;
    &:hover {
      background-color: $tableRowHoverColor !important;
    }
  }
}
