.coLagerverwaltungumlagerung {
  &__forwardIcon {
    color: $color-black !important;
    width: 100% !important;
    font-size: 200% !important;
    transition: all 0.5s !important;

    @include materialGridMd {
      transform: rotate(90deg);
    }
  }

  &__forwardIconSection {
    @include materialGridMd {
      padding: 0 !important;
      margin: 0 !important;
    }
  }

  &__tableContainer {
    height: 200px !important;
  }
}
