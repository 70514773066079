.table {
  &__cell-no-padding {
    margin: 0 !important;
    padding: 0 !important;
    box-sizing: border-box !important;
  }

  &__container {
    border-top: none;
    border-bottom: none !important;
    background-color: $tableContainerBackgroundColor !important;
    max-height: 650px !important;

    &--border {
      border-top: none;
      background-color: $tableContainerBackgroundColor !important;
      max-height: 650px !important;
    }
  }

  &__container.heightFour {
    max-height: 400px !important;
  }
  &__container.heightFive {
    max-height: 500px !important;
  }
  &__container.border__noTop {
    border: 1px solid $borderColor !important;
    border-top: none !important;
  }

  &__headerCell {
    background-color: $tableTitleBackgroundColor !important;
    width: 130px !important;
    white-space: nowrap;
  }

  &__headerCellNoWidth {
    background-color: $tableTitleBackgroundColor !important;
    width: 8px !important;
  }

  &__headerTabs {
    .MuiTabs-flexContainer {
      justify-content: space-evenly !important;
      align-items: center !important;

      @include coQualitaetssicherungBreakpointOne {
        flex-direction: column !important;
      }
    }

    .MuiTabs-indicator {
      @include coQualitaetssicherungBreakpointOne {
        display: none !important;
      }
    }
  }

  &__row--hover {
    transition: all 0.3s;
    cursor: pointer;
    &:hover {
      background-color: $tableRowHoverColor !important;
    }
  }

  &__row--active {
    background-color: $tableRowActiveColor !important;
  }

  &__more {
    padding: 0 0 !important;
    background-color: $tableMoreBackgroundColor !important;
  }

  &__rowTitle {
    width: 130px !important;
    white-space: nowrap;
  }

  &__title {
    padding: 10px 0 !important;
    background-color: $tableTitleBackgroundColor !important;
    border: 1px solid $borderColor;
    border-bottom: none;
  }

  &__titleButton {
    padding: 7px 14px !important;
    color: $color-white !important;
    margin-left: 20px !important;

    @include coBestandstabelleBreakpoint {
      display: block !important;
      margin: 10px auto !important;
    }
  }
}
