.header * {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.header__container {
  max-width: 1400px;
  margin: 0 auto;
}

.header__container a {
  text-decoration: none;
  color: #111;
  cursor: pointer;
}

.header__container button {
  cursor: pointer;
}

/*********** kostenlost-modal ********/
.modal__kostenlosTesten {
  transition: all 400ms;
  background-image: url('https://traceport.de/assets/images/tracporthome/traceport_tab.png');
  width: 100%;
  top: 0;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 1.2rem;
  height: 100vh;
  position: fixed;
  z-index: -1;
  opacity: 0;
  visibility: hidden;
}

.modal__kostenlosTesten.modal__kostenlosTesten--open {
  visibility: visible;
  z-index: 100;
  opacity: 1;
}

.modal__kostenlosTestenContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  height: calc(100vh - 2.2rem);
}

.modal__kostenlosTestenContainerCloser {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
}

.modal__kostenlosTestenContainerCloser .divContainer {
  width: 25px;
  height: 25px;
  cursor: pointer;
}

.modal__kostenlosTestenContainerCloser .div1 {
  height: 25px;
  width: 3px;
  margin-left: 12px;
  background-color: rgb(210, 210, 210);
  transform: rotate(45deg);
  z-index: 1;
}

.modal__kostenlosTestenContainerCloser .div2 {
  height: 25px;
  width: 3px;
  background-color: rgb(210, 210, 210);
  transform: rotate(90deg);
  z-index: 2;
}

.modal__kostenlosTestenContainer .header {
  padding: 3rem 0 0;
}

.modal__kostenlosTestenContainer .header img {
  transition: all 400ms;
}

.modal__kostenlosTestenContainer .content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.modal__kostenlosTestenContainer .contentContainer {
  width: 50%;
  min-width: 510px;
  padding: 0 12px;
}

.modal__kostenlosTestenContainer .contentContainer .top,
.modal__kostenlosTestenContainer .contentContainer .middle {
  margin-bottom: 3rem;
}

.modal__kostenlosTestenContainer .contentContainer .top h2 {
  color: white;
  font-size: 2.25rem;
  font-weight: 400;
  line-height: 1.3;
  width: 700px;
  margin-left: -90px;
  text-align: center;
}

.modal__kostenlosTestenContainer .contentContainer .middle .inputContainer {
  position: relative;
}

.modal__kostenlosTestenContainer .contentContainer .middle .inputContainer > input {
  width: 100%;
  border-radius: 8px;
  outline: none;
  font-size: 16px;
  padding: 16px 178px 16px 18px;
  transition: all 0.3s;
  border: 2px solid transparent;
  border-bottom: none;
}

.modal__kostenlosTestenContainer .contentContainer .middle .inputContainer > input.kostenlosTestenEmail--invalid {
  border-color: red;
}

.modal__kostenlosTestenContainer .contentContainer .middle .inputContainer > .circularProgress {
  position: absolute !important;
  top: 7px;
  left: 8px;
  display: none;
}

.modal__kostenlosTestenContainer .contentContainer .middle .inputContainer > .circularProgress--active {
  display: block;
}

.modal__kostenlosTestenContainer .contentContainer .middle .inputContainer > .kostenlosTestenErrorInputMessage {
  display: block;
  color: #fff;
  font-size: 13px;
  font-weight: 600;
  line-height: 1.1;
  padding: 0.5rem 1rem;
  transition: all 0.3s;
  visibility: hidden;
  opacity: 0;
}

.modal__kostenlosTestenContainer .contentContainer .middle .inputContainer > .kostenlosTestenErrorInputMessage--active {
  visibility: visible;
  opacity: 1;
  display: block;
}

.modal__kostenlosTestenContainer .contentContainer .middle .inputContainer .kostenlosTestenLink {
  position: absolute;
  top: 9%;
  right: 8px;
}

.modal__kostenlosTestenContainer .contentContainer .middle .kostenlosTestenLink > button {
  border: none;
  border-radius: 4px;
  font-weight: 600;
  transition: all 0.15s;
  user-select: none;
  display: block;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.08), 0 1px 3px 0 rgba(0, 0, 0, 0.06);
  text-decoration: none;
  width: fit-content;
  color: #fff;
  background-image: linear-gradient(#4d90e1, #417edc);
  padding: 10px 16px;
  cursor: pointer;
  font-size: 0.875rem;
}

.modal__kostenlosTestenContainer .contentContainer .middle .kostenlosTestenLink > button:hover {
  transform: translateY(-1px);
  background-image: linear-gradient(#5b99e3, #5088df);
}

.modal__kostenlosTestenContainer .contentContainer .middle .kostenlosTestenLink > button:active {
  transform: none !important;
}

.modal__kostenlosTestenContainer .contentContainer .bottom p {
  font-size: 14px;
  color: rgb(180, 180, 180);
  font-weight: 500;
}

.modal__kostenlosTestenContainer .contentContainer p a {
  margin: 0 4px;
  border-bottom: 1px solid rgb(180, 180, 180);
  transition: border-color 0.3s;
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}

.modal__kostenlosTestenContainer .contentContainer p a:hover {
  border-color: transparent;
}

@media only screen and (max-width: 991px) {
  .modal__kostenlosTestenContainer .contentContainer .top h2 {
    font-size: 2rem;
  }
}

@media only screen and (max-width: 767px) {
  .modal__kostenlosTesten {
    background-image: url('https://traceport.de/assets/images/tracporthome/traceport_phone.png');
  }

  .modal__kostenlosTestenContainer .contentContainer {
    width: 73%;
    min-width: unset;
  }

  .modal__kostenlosTestenContainer .contentContainer .top h2 {
    font-size: 1.5rem;
    width: auto;
    margin-left: 0;
    text-align: left;
  }

  .modal__kostenlosTestenContainer .contentContainer .middle .inputContainer > input {
    padding: 16px;
    font-size: 15px;
  }

  .modal__kostenlosTestenContainer .contentContainer .middle .inputContainer > .kostenlosTestenErrorInputMessage {
    display: none;
  }

  .modal__kostenlosTestenContainer .contentContainer .middle .inputContainer > .kostenlosTestenErrorInputMessage--active {
    display: block !important;
  }

  .modal__kostenlosTestenContainer .contentContainer .middle .inputContainer .kostenlosTestenLink {
    position: static;
    transform: none;
    margin-top: 15px;
  }

  .modal__kostenlosTestenContainer .contentContainer .middle .inputContainer .kostenlosTestenLink button {
    width: 100%;
    text-align: center;
  }

  .modal__kostenlosTestenContainer .contentContainer .bottom p {
    font-size: 12px;
  }
}

@media only screen and (max-width: 490px) {
  .modal__kostenlosTestenContainer .contentContainer {
    width: 100%;
  }
}

/*********** topbar **********/
.navigation__topbar {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 16px 32px;
}

.navigation__topbar .navigation__pageLinks,
.navigation__topbar .navigation__appLinks {
  display: flex;
  justify-content: center;
  align-items: center;
}

.navigation__topbar .navigation__pageLinks > div,
.navigation__topbar .navigation__appLinks > .navigation__loginLink {
  padding: 0 0.75rem;
}

.navigation__topbar .navigation__pageLinks > div > a {
  padding: 0.5625rem 0;
  font-size: 0.9375rem;
  position: relative;
  cursor: pointer;
}

.navigation__topbar .navigation__loginLink > a {
  padding: 0.5625rem 0;
  font-size: 0.9375rem;
  position: relative;
  font-weight: 600;
}

.navigation__topbar .navigation__loginLink > a:hover {
  color: rgb(82, 142, 222);
}

.navigation__topbar .navigation__kostenlosTestenLink > button {
  border: none;
  background-image: linear-gradient(#4d90e1, #417edc);
  padding: 12px 20px;
  color: #fff;
  font-size: 1rem;
  border-radius: 4px;
  font-weight: 600;
  transition: all 0.15s;
  user-select: none;
  display: block;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.08), 0 1px 3px 0 rgba(0, 0, 0, 0.06);
}

.navigation__topbar .navigation__kostenlosTestenLink > button:hover {
  transform: translateY(-1px);
  background-image: linear-gradient(#5b99e3, #5088df);
}

.navigation__topbar .navigation__kostenlosTestenLink > button:active {
  transform: none !important;
}

.navigation__topbar .navigation__pageLinks > div > a::after,
.navigation__topbar .navigation__loginLink > a::after {
  background-color: #b7b7b8;
  bottom: 0;
  content: '';
  display: block;
  font-size: 0;
  height: 2px;
  left: 0;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  transition: opacity 0.2s;
  width: 100%;
}

.navigation__topbar .navigation__pageLinks > div > a:hover::after,
.navigation__topbar .navigation__loginLink > a:hover::after {
  opacity: 1;
}

.navigation__openSidebar {
  display: none;
  margin-right: 10px;
  cursor: pointer;
}

.navigation__openSidebar > div {
  width: 32px;
  height: 4px;
  background-color: black;
  margin: 4px 0;
}

/*********** sidebar **********/
.navigation__sidebar {
  width: 100%;
  height: 100vh;
  background-color: rgb(249, 249, 249);
  position: absolute;
  top: 0;
  left: 100%;
  transition: transform 0.5s;
  display: flex;
  flex-direction: column;
  z-index: 100;
}

.navigation--sidebarOpen .navigation__sidebar {
  transform: translateX(-100%);
}

.sidebar__top {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ececec;
  padding: 16px 32px 10px;
}

.navigation__closeSidebar .divContainer {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.navigation__closeSidebar .div1 {
  height: 20px;
  width: 3px;
  margin-left: 12px;
  background-color: black;
  transform: rotate(45deg);
  z-index: 1;
}

.navigation__closeSidebar .div2 {
  height: 20px;
  width: 3px;
  background-color: black;
  transform: rotate(90deg);
  z-index: 2;
}

.sidebar__middle {
  padding: 32px;
}

.sidebar__middle .navigation__pageLinks {
  margin-top: 20px;
}

.sidebar__middle .navigation__pageLinks > div {
  margin-bottom: 24px;
}

.sidebar__middle .navigation__pageLinks > div > a {
  font-size: 1.25rem;
  color: rgb(26, 25, 25);
}

.sidebar__middle .navigation__pageLinks > div > a:hover,
.sidebar__bottom .navigation__appLinks > div > a:hover,
.sidebar__bottom .navigation__appLinks > div > button:hover {
  color: #4a89dc;
}

.sidebar__bottom {
  /*moves the element to the bottom of the father element*/
  border-top: 1px solid #ececec;
  margin-top: 150px;
  padding: 32px 0;
}

.sidebar__bottom .navigation__appLinks {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: fit-content;
  margin: 0 auto;
}

.sidebar__bottom .navigation__appLinks > div {
  padding: 0 1rem;
}

.sidebar__bottom .navigation__appLinks > div > a,
.sidebar__bottom .navigation__appLinks > div > button {
  color: rgb(26, 25, 25);
  font-size: 0.875rem;
  font-weight: 600;
  margin: 0 24px;
  padding: 10px 0;
  line-height: 0;
}

.sidebar__bottom .navigation__appLinks > div > button {
  background-color: inherit;
  border: none;
  word-break: break-all;
  min-width: 160px !important;
}

.sidebar__bottom .navigation__appLinks .navigation__loginLink::after {
  content: '|';
  color: rgb(172, 172, 172);
}

/*********** media query **********/

@media only screen and (max-width: 1199px) {
  .navigation__topbar {
    justify-content: space-between;
  }

  .navigation__topbar .navigation__pageLinks {
    display: none;
  }

  .navigation__openSidebar {
    display: block;
  }
}

@media only screen and (max-width: 1000px) {
  .navigation__logo > a > img {
    width: 130px;
  }

  .navigation__topbar {
    padding: 10px 32px;
  }
}

@media only screen and (max-width: 767px) {
  .navigation__topbar .navigation__appLinks .navigation__loginLink,
  .navigation__topbar .navigation__appLinks .navigation__kostenlosTestenLink {
    display: none;
  }
}

@media only screen and (max-width: 345px) {
  .sidebar__bottom .navigation__appLinks {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: fit-content;
    margin: 0 auto;
  }

  .sidebar__bottom .navigation__appLinks > div {
    text-align: center;
  }

  .sidebar__bottom .navigation__appLinks .navigation__loginLink::after {
    display: none;
  }
}
