.coLiveTracking {
  &__iframe {
    width: 100%;
    height: 60vh;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;
    border: none;

    &--fullWidth {
      height: 100vh;
      box-shadow: none;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 10000;
    }
  }

  &__closeFullWidth {
    position: absolute !important;
    top: 5px;
    right: 12px;
    padding: 6px !important;
    opacity: 0;
    visibility: hidden;
    z-index: 1000000000001;

    &--active {
      opacity: 1;
      visibility: visible;
    }
  }
}

.overSection {
  height: 50px;
  background: white;
  position: absolute;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
}

.overSection img {
  width: 165px;
  padding: 7px 15px;
  display: none;
}

.overSection.zIndex {
  z-index: 1000000000;
  height: 49px;
}

.overSection.zIndex img {
  display: block;
}
