.backdrop {
  &__container {
    z-index: 10000 !important;
  }
}

.border {
  border: 1px solid $borderColor;

  &__top {
    border-top: 1px solid $borderColor;
  }
  &__bottom {
    border-bottom: 1px solid $borderColor;
  }
  &__left {
    border-left: 1px solid $borderColor;
  }
  &__right {
    border-right: 1px solid $borderColor;
  }

  &__noTop {
    border-top: none !important;
  }

  &__noBottom {
    border: 1px solid $borderColor !important;
    border-bottom: none !important;
  }

  &__none {
    border: none !important;
  }
}

.color__timelineStart {
  background-color: $colorTimelineStartBackgroundColor !important;
}

.color__timelineEnd {
  background-color: $colorTimelineEndBackgroundColor !important;
}

.cursorPointer {
  cursor: pointer !important;
}

.display__block {
  display: block !important;
}

.fontWeihgtBold {
  font-weight: bolder !important;
}

.form__anmeldungsform {
  padding: 30px 20px !important;
}

.galleryImageSection {
  border: 1px solid $borderColor !important;
  border-top: none !important;
  padding: 10px !important;

  &:nth-child(odd) {
    background-color: $borderColor !important;
  }
}

.gallerySection {
  border: 1px solid $borderColor !important;
  padding: 10px !important;

  &__inGroup {
    border: 1px solid $borderColor !important;
    margin-top: 10px !important;
  }
}

.globalSite {
  &__headerIcon {
    width: 48px !important;
    height: auto !important;
  }
}

.heightThree {
  height: 200px !important;
}

.maxHeight__330 {
  max-height: 330px !important;
}

.table__container.maxHeight__330 {
  max-height: 330px !important;
}

.heightFour {
  max-height: 400px !important;
}

.heightFive {
  max-height: 500px !important;
}

.height__550 {
  height: 550px !important;
}

.marginFourty {
  margin-top: 40px !important;
}

.marginThirty {
  margin-top: 30px !important;
}

.marginTwenty {
  margin-top: 20px !important;
}

.marginEighty {
  margin-top: 80px !important;
}

.marginLeft {
  margin-left: 20px !important;
}

.marginLeft__10 {
  margin-left: 10px !important;
}

.marginLeft__20 {
  margin-left: 20px !important;
}

.marginRight {
  margin-right: 5px !important;
}

.marginTopBottomTen {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.noWrap {
  white-space: nowrap !important;
}

.padding5 {
  padding: 5px !important;
}

.paddingTwenty {
  padding: 20px !important ;
}

.paddingTopZero {
  padding-top: 0 !important;
}

.paddingZero {
  padding: 0 !important;
}

.padding__bottom40 {
  padding-bottom: 40px !important;
}

.padding {
  &__bottom {
    &--5 {
      padding-bottom: 5px;
    }
  }
}

.snackbarProvider {
  &__container {
    width: 400px !important;
    font-size: 1.05rem !important;
  }
}

.statusSelect {
  padding: 3px 7px !important;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  color: $color-white !important;
  width: 145px !important;
  &--red {
    background-color: $formSelectColorRed !important;
  }
  &--orange {
    background-color: $formSelectColorOrange !important;
  }
  &--green {
    background-color: $formSelectColorGreen !important;
  }
}

.width {
  &__min190 {
    min-width: 190px !important;
  }

  &__min270 {
    min-width: 270px !important;
  }

  &150 {
    width: 150px !important;
  }
}

.widthOneSeventy {
  width: 170px !important;
}
