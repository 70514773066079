.typography {
  font-weight: 500 !important;

  &__2rem {
    font-size: 2rem !important;
  }

  &__accordionSummary {
    font-weight: 500 !important;
    font-size: 140% !important;
    color: $typographySingleTableTitle !important;
  }

  &__breadcrumbs {
    font-weight: 500 !important;
    @include typographyBreakpointTwo {
      font-size: 90% !important;
    }
  }

  &__dialogTitle {
    font-weight: 500 !important;
    font-size: 120% !important;
    @include typographyBreakpointTwo {
      font-size: 90% !important;
    }
  }

  &__gallery {
    cursor: pointer;
    font-weight: 500 !important;
    font-size: 95% !important;
    background-color: $typographyBackGroundColor !important;
    margin-top: 20px !important;
    width: 80% !important;
    &--disabled {
      opacity: 0.5 !important;
    }
    &--fullWidth {
      width: 100% !important;
    }
    transition: all 0.3s !important;
    &:hover {
      box-shadow: 0 0 11px $shadowOnHoverColor;
    }
    @include materialGridSm {
      width: 100% !important;
    }
  }

  // form
  &__formTitle {
    font-weight: 500 !important;
    font-size: 240% !important;
    color: $typographyFormTitle !important;
  }

  // webpages header
  &__headerTitle {
    font-weight: 500 !important;
    margin: 0 0 0 10px !important;
    font-size: 240% !important;
    color: $typographyHeaderTitleColor !important;

    @include typographyBreakpointTwo {
      font-size: 150% !important;
      margin: 0 0 0 5px !important;
    }
  }

  // email sent message
  &__message {
    font-weight: 500 !important;
    color: $typographyMessage !important;
    font-size: 120% !important;

    @include typographyBreakpointTwo {
      font-size: 120% !important;
    }
  }

  &__sectionTitle {
    font-weight: 500 !important;
    font-size: 200% !important;
    color: $typographyHeaderTitleColor !important;

    @include typographyBreakpointTwo {
      font-size: 120% !important;
    }

    &--small {
      font-weight: 500 !important;
      font-size: 150% !important;
      color: $typographyHeaderTitleColor !important;

      @include typographyBreakpointTwo {
        font-size: 120% !important;
      }
    }
  }

  // single Table
  &__singleTableTitle {
    font-weight: 500 !important;
    font-size: 140% !important;
    color: $typographySingleTableTitle !important;
  }

  &__singleTableMore {
    font-weight: 500 !important;
    font-size: 120% !important;
    color: $typographySingleTableTitle !important;
  }

  &__singleTableRowTitle {
    font-size: 100% !important;
    font-weight: 600 !important;
    color: $typographySingleTableRowTitle !important;

    &--disabled {
      font-size: 100% !important;
      color: $typographyColorGrey !important;
      user-select: none;
    }
  }

  &__singleTableRowContent {
    font-size: 100% !important;
    font-weight: 400 !important;
    color: $typographySingleTableRowContent !important;
  }

  // timeline
  &__timeline {
    font-size: 120% !important;
    font-weight: 400 !important;
    color: $typographyTimeline !important;
  }

  &__timelineOpposite {
    font-size: 120% !important;
    font-weight: 400 !important;
    color: $typographyTimelineOpposite !important;
  }

  &__colorDamage {
    color: $typographyColorRed !important;
  }

  &__colorSuccess {
    color: $typographyColorGreen !important;
  }
}
