.column.is-2.buttons.are-small.has-addons {
  display: none;
}

.button.is-black.is-marginless.has-margin-left-5.has-margin-right-5 {
  display: none;
}

.column.is-3.buttons.are-small.has-addons.is-centered {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0 !important;

  .button.is-black.is-marginless {
    padding: 3px 10px !important;
    font-size: 14px !important;
    color: $color-white !important;
    border: none;
    outline: none;
    margin: 5px;
    border-radius: 3px;
    cursor: pointer;
    background-color: $buttonColorGreen !important;
    &:hover {
      background-color: $buttonOnHoverGreen !important;
    }

    &:first-child::after {
      content: '<';
      font-size: 20px;
      font-weight: 800;
    }
    &:last-child::after {
      content: '>';
      font-size: 20px;
      font-weight: 800;
    }
    .icon.is-small .material-icons {
      display: none;
    }
  }
}

body > div.MuiDialog-root > div.MuiDialog-container.MuiDialog-scrollPaper > div > div.MuiDialogContent-root.dialog__content > div > div:nth-child(2) > div > div:nth-child(1) > div > div > canvas {
  width: 100%;
  height: unset !important;
}

body
  > div.MuiDialog-root
  > div.MuiDialog-container.MuiDialog-scrollPaper
  > div
  > div.MuiDialogContent-root.dialog__content.coWareneinganglieferschein__dilaogContent
  > div
  > div:nth-child(2)
  > div
  > div:nth-child(1)
  > div
  > div {
  height: auto !important;
}
