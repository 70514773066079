.footer__container {
  padding: 12px;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}

.footer__container a {
  text-decoration: none;
  color: rgb(92, 98, 107);
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  cursor: pointer;
}

.footer__container .left {
  display: flex;
  flex-direction: column;
  padding: 12px;
}

.footer__container .left .bottom {
  margin-top: 5px;
}

.footer__container .left .bottom a {
  margin: 0 4px;
  border-bottom: 1px solid rgb(92, 98, 107);
  font-size: 12px;
  transition: border-color 0.3s;
}

.footer__container .left .bottom a:hover {
  border-color: transparent;
}
