.camera {
  &__container {
    padding: 0 !important;
    position: relative;
  }

  &__video {
    width: 100%;
  }

  &__takePhotoIcon {
    font-size: 50px !important;
    color: $color-white;
  }

  &__takePhotoIconButton {
    position: absolute !important;
    left: 50%;
    bottom: 0px;
    transform: translateX(-50%) !important;
  }
}

.coBarCodeScanner {
  overflow: hidden;
  position: relative;
  width: 100%;
  padding-top: 100%;
  .drawingBuffer {
    display: none !important;
  }
  video {
    top: 0px;
    left: 0px;
    display: block;
    position: absolute;
    overflow: hidden;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &::after {
    content: '';
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    box-shadow: rgba(255, 0, 0, 0.5) 0px 0px 0px 5px inset;
    position: absolute;
    width: 100%;
    height: 5px;
  }
  div {
    height: 0;
  }
}

.coMultipleScanner {
  &__formControl {
    display: flex !important;
    flex-direction: row !important;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
  }

  &__radioGroup {
    display: flex !important;
    flex-direction: row !important;
    margin-left: 20px;
    span {
      font-weight: 500 !important;
      padding: 0px 2px !important;
      font-size: 105% !important;

      @include cameraAndScannerBreakpointOne {
        font-size: 90% !important;
      }
    }
  }

  &__textField {
    width: 32% !important;

    @include materialGridLg {
      width: 50% !important;
    }

    @include materialGridSm {
      width: 100% !important;
    }
  }
}
