.container {
  &__container {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }

  &__anmeldungscontainer {
    margin: 60px 0 40px !important;
    padding-bottom: 20px;
  }

  &__listItem {
    padding: 0 !important;
  }
}
