.coNavigation {
  &__border {
    &--desktop {
      @include coNavigationDesktopBreakpoint {
        border-bottom: 1px solid $color-white;
      }
    }

    &--mobile {
      @include coNavigationMobileBreakpoint {
        border-bottom: 1px solid $color-white;
      }
    }
  }

  &__divider {
    margin: 0 0 5px 0 !important;
  }

  &__icon {
    font-size: 30px !important;
  }

  &__link {
    text-decoration: none;
    color: #000000;

    &:active {
      color: #000000;
    }
  }

  &__listIcon {
    width: 32px;
    height: auto;
  }

  &__listItemText {
    font-weight: 500 !important;
    color: $color-black;
  }

  &__listItemTitle {
    margin: 10px 0 0 0;
    font-size: 20px;
    font-weight: 700;
  }

  &__listItemTitleFirst {
    margin: 0;
    font-size: 20px;
    font-weight: 700;
  }

  &__liveTracking > * {
    box-shadow: none !important;
  }

  &__liveTrackingAccordion.Mui-disabled {
    background: none !important;
  }

  &__liveTrackingAccordionSummary {
    padding: 0 !important;
  }

  &__liveTrackingAccordionSummary.Mui-disabled {
    opacity: 1 !important;
  }

  &__liveTrackingAccordionSummary .MuiAccordionSummary-expandIcon {
    padding-top: 7px !important;
  }

  &__liveTrackingAccordionSummary .MuiAccordionSummary-expandIcon.Mui-expanded {
    padding-top: 12px !important;
    padding-bottom: 7px;
  }

  &__liveTrackingAccordionSummary > .MuiAccordionSummary-content {
    margin: 0 !important;
  }

  &__title {
    font-size: 28px !important;
    font-weight: 500 !important;
  }

  &__upperPart {
    &--desktop {
      padding: 10px 10px 20px;
    }

    &--mobile {
      padding: 10px;
    }
  }

  &__underPart {
    background-color: $topNavBackgroundColor !important;

    &--inIframe {
      background-color: $topNavInIframeBackgroundColor !important;
    }
  }

  &__menuItemGrid {
    @include materialGridSmMin {
      border-right: 2px solid $color-main;
    }
    background-color: $topNavBackgroundColor;

    &--active {
      background-color: $topNavItemActiveColor;

      p {
        color: $topNavAccordionBackgroundColor !important;
        font-weight: 900 !important;
      }
    }

    &--inIframe {
      background-color: $topNavInIframeBackgroundColor;
      border-right: 2px solid $color-Iframe-main;

      &.coNavigation__menuItemGrid--active {
        background-color: $topNavItemActiveInIframeColor !important;

        p {
          color: $topNavAccordionInIframeBackgroundColor !important;
        }
      }
    }
  }

  &__toolbar {
    padding: 0 !important;
  }

  &__titleContainer {
    transition: all 0.4s !important;
    max-height: 40px !important;
  }

  &__titleContainer--height {
    max-height: 0 !important;
    padding: 0 !important;
  }

  &__topNavBreadcrumbs {
    transition: all 0.4s !important;
    opacity: 0;
    font-size: 16px !important;
    width: 300px !important;
    height: 0 !important;

    &--active {
      opacity: 1;
      height: 20px !important;
    }
  }

  &__topNavList {
    width: 100%;
  }

  &__topNavListItem {
    padding-left: 5px !important;

    &--active {
      background-color: $topNavItemActiveColor !important;
    }

    &--inIframe {
      &.coNavigation__topNavListItem--active {
        background-color: $topNavItemActiveInIframeColor !important;
      }
    }
  }

  &__topNavListItemIcon {
    min-width: 37px !important;
  }

  &__topNavListItemText {
    font-weight: 700;
    color: $topNavLinksColor;
    text-shadow: -1px -1px 0 $topNavLinksTextShadowColor, 1px -1px 0 $topNavLinksTextShadowColor, -1px 1px 0 $topNavLinksTextShadowColor, 1px 1px 0 $topNavLinksTextShadowColor;
  }

  &__topNavLiveTracking {
    position: relative;
    user-select: none;
  }

  &__topNavLiveTracking:hover {
    background-color: $topNavBackgroundColor;
  }

  &__topNavLiveTracking.coNavigation__topNavListItem--inIframe:hover {
    background-color: $topNavInIframeBackgroundColor;
  }

  &__topNavLiveTrackingText {
    display: flex;
    align-items: center;
  }

  &__topNavLiveTrackingTrackers {
    position: absolute !important;
    width: 125px !important;
    top: 0;
    padding-top: 0 !important;
    opacity: 0;
    visibility: hidden;
    left: 100%;
    transition: all 0.3s;
    background-color: $topNavAccordionBackgroundColor;
    color: $color-white;
    border-radius: 5px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;
    border-top-left-radius: 0px;
    max-height: 432px;
    overflow-y: auto;

    &--inIframe {
      background-color: $topNavAccordionInIframeBackgroundColor;
    }
  }

  &__topNavLiveTracking:hover &__topNavLiveTrackingTrackers {
    opacity: 1;
    visibility: visible;
  }

  &__accordion {
    background-color: $topNavAccordionBackgroundColor !important;
    border-radius: 0 !important;
    box-shadow: none !important;
    position: relative;
    min-width: 132px !important;
    transition: all 0.5s !important;

    @include materialGridSmMin {
      .MuiCollapse-container.MuiCollapse-hidden .coNavigation__accordionDetails {
        opacity: 0 !important;
        visibility: hidden !important;
      }

      .MuiCollapse-container.MuiCollapse-entered .coNavigation__accordionDetails {
        opacity: 1 !important;
      }
    }

    &--active {
      background-color: $topNavItemActiveColor !important;
    }

    &--inIframe {
      background-color: $topNavAccordionInIframeBackgroundColor !important;

      &.coNavigation__accordion--active {
        background-color: $topNavItemActiveInIframeColor !important;
      }
    }
  }

  &__accordionDetails {
    margin-left: 20px !important;

    @include materialGridSmMin {
      position: absolute !important;
      margin-left: 0 !important;
      min-width: fit-content !important;
      box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;
      width: 100% !important;
      border-bottom-right-radius: 5px;
      border-bottom-left-radius: 5px;
    }

    transition: all 0.5s !important;
    background-color: $topNavAccordionBackgroundColor;
    padding: 0 !important;
    z-index: 2;

    &--inIframe {
      background-color: $topNavAccordionInIframeBackgroundColor;
    }
  }

  &__accordionSummaryTypography {
    font-weight: 500 !important;
    font-size: 130% !important;
    color: $topNavTextEnabledColor !important;
    width: 100%;
    @include materialGridSmMin {
      text-align: center;
    }

    &--disabled {
      font-weight: 500 !important;
      font-size: 130% !important;
      color: $topNavTextDisabledColor !important;
      width: 100%;
      @include materialGridSmMin {
        text-align: center;
      }
    }
  }

  &__accordionSummary {
    display: flex !important;
    justify-content: flex-start !important;

    .MuiAccordionSummary-content {
      flex-grow: 0 !important;
      width: 100%;
    }
  }

  &__accordionSummaryIcon {
    color: $topNavTextEnabledColor;

    &--disabled {
      color: $topNavTextDisabledColor;
    }
  }

  &__menuItemGrid {
    flex-grow: 1 !important;
  }
}
