.dialog {
  &__content {
    padding: 6px 24px 20px 24px !important;

    @include coSucheBreakpointOne() {
      padding: 6px 12px 20px 12px !important;
    }
  }

  &__titleAsHeader {
    background-color: rgba(0, 0, 0, 0.4) !important;
    padding: 20px !important;

    button {
      margin-left: 10px !important;
    }

    @include dialogBreakPointOne {
      flex-direction: column !important;
      align-items: flex-start;
      justify-content: flex-start;
      padding: 2px !important;
      button {
        display: block !important;
        margin: 10px 0 0 0 !important;
      }
    }

    @include dialogBreakPointTwo {
      button {
        padding: 5px !important;
        font-size: 13px !important;
      }
      a {
        padding: 5px !important;
        font-size: 13px !important;
      }
    }
  }
}
