.icon {
  &__false {
    font-size: 26px !important;
    color: $buttonColorRed;
  }

  &__true {
    font-size: 26px !important;
    color: $iconColorGreen;
  }

  &__red {
    color: $color-Iframe-main !important;
  }

  &__green {
    color: $iconColorGreen;
  }
}
