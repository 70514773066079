.coAuftragsverwaltungauftrag {
  &__button {
    margin-top: 10px !important;
  }

  &__formControl {
    padding-top: 10px !important;
    width: fit-content !important;
  }

  &__formLabel {
    margin-bottom: 8px !important;
  }

  &__tableContainer {
    max-width: 900px !important;
    height: 200px !important;
    border-bottom: 1px solid $borderColor !important;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;

    &--disable {
      user-select: none;
      opacity: 0.5 !important;
      pointer-events: none !important;
    }
  }

  &__tableTitle {
    max-width: 900px !important;
    background-color: inherit !important;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    &--disable {
      user-select: none;
      opacity: 0.5 !important;
    }
  }
}
