.coArtikelverwaltungartikel {
  &__buttonContainer {
    @include materialGridMdMin {
      display: block !important;
    }
  }
  &__contentContainer {
    @include materialGridMdMin {
      flex-direction: row-reverse !important;
      max-width: 1176px;
    }
  }
  &__table {
    height: 400px !important;
    @include materialGridMdMin {
      height: 550px !important;
    }
  }
}
