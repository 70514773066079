.coTracking {
  &__bubblesContainer {
    margin-top: 40px !important;
    margin-bottom: 40px !important;

    @include materialGridSm {
      margin-top: 5px !important;
      margin-bottom: 20px !important;
    }
  }
}
