.button {
  height: 52px !important;
  padding: 0 40px !important;
  font-size: 18px !important;

  @include buttonsBreakpointOne() {
    font-size: 14px !important;
    padding: 0 33px !important;
  }

  &__middle {
    padding: 10px 20px !important;
    font-size: 14px !important;

    &--redOutLined {
      padding: 10px 20px !important;
      font-size: 14px !important;
      border: 1px solid $buttonColorRed !important;
      &:hover {
        background-color: $buttonOnHoverRed !important;
        color: $color-white !important;
      }
    }

    &--redFilled {
      padding: 10px 20px !important;
      font-size: 14px !important;
      color: $color-white !important;
      background-color: $buttonColorRed !important;
      &:hover {
        background-color: $buttonOnHoverRed !important;
      }
    }

    &--greenFilled {
      padding: 10px 20px !important;
      font-size: 14px !important;
      color: $color-white !important;
      background-color: $buttonColorGreen !important;
      &:hover {
        background-color: $buttonOnHoverGreen !important;
      }
    }

    &--greenOutLined {
      padding: 10px 20px !important;
      font-size: 14px !important;
      border: 1px solid $buttonColorGreen !important;
      &:hover {
        background-color: $buttonOnHoverGreen !important;
        color: $color-white !important;
      }
    }
  }

  &__small {
    padding: 5px 10px !important;
    font-size: 12px !important;
    color: $color-black !important;

    &--cWhite {
      color: $color-white !important;
      padding: 5px 10px !important;
      font-size: 12px !important;
    }

    &--greenFilled {
      padding: 5px 10px !important;
      font-size: 12px !important;
      color: $color-white !important;
      background-color: $buttonColorGreen !important;
      &:hover {
        background-color: $buttonOnHoverGreen !important;
      }
    }

    &--greenOutLined {
      padding: 5px 10px !important;
      font-size: 12px !important;
      border: 1px solid $buttonColorGreen !important;
      &:hover {
        background-color: $buttonOnHoverGreen !important;
        color: $color-white !important;
      }
    }

    &--redOutLined {
      padding: 5px 10px !important;
      font-size: 12px !important;
      border: 1px solid $buttonColorRed !important;
      &:hover {
        background-color: $buttonOnHoverRed !important;
        color: $color-white !important;
      }
    }
  }

  &__icon--deleteImage {
    color: $color-white !important;
    opacity: 0.7 !important;
    margin-left: 20px !important;
    background-color: $buttonColorRed !important;
    padding: 1px !important;
    &:hover {
      opacity: 1 !important;
    }
  }

  &__iconButton--close {
    background-color: $buttonColorRedTransparent !important;
    color: $color-white !important;
    padding: 3px !important;

    @include buttonBreakpointTwo {
      padding: 1px !important;
    }

    &:hover {
      background-color: $buttonColorRed !important;
    }
  }

  &__icon--smallGreen {
    color: $color-main !important;
    font-size: 30px !important;
  }

  &__icon--smallRed {
    color: $color-Iframe-main !important;
    font-size: 30px !important;
  }

  &__icon--table {
    &Green {
      color: $color-white !important;
      padding: 7px !important;
      background-color: $buttonColorGreen !important;
      &:hover {
        background-color: $buttonOnHoverGreen !important;
      }
    }

    &Red {
      color: $color-white !important;
      padding: 7px !important;
      background-color: $buttonColorRed !important;
      padding: 7px !important;
      &:hover {
        background-color: $buttonOnHoverRed !important;
      }
    }
  }
}
