.coSignUp {
  &__container {
    margin: 60px 0 40px 0 !important;
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__form {
    padding: 30px 20px !important;
  }

  &__link {
    text-align: center;
  }
}
