// scss resources
@mixin buttonsBreakpointOne() {
  @media only screen and (max-width: 350px) {
    @content;
  }
}

@mixin dialogBreakPointOne() {
  @media only screen and (max-width: 405px) {
    @content;
  }
}

@mixin dialogBreakPointTwo() {
  @media only screen and (max-width: 330px) {
    @content;
  }
}

@mixin imagesBreakpointOne() {
  @media only screen and (max-width: 1180px) {
    @content;
  }
}

@mixin imagesBreakpointTwo() {
  @media only screen and (max-width: 560px) {
    @content;
  }
}

@mixin imagesBreakpointThree() {
  @media only screen and (max-width: 411px) {
    @content;
  }
}

@mixin typographyBreakpointTwo() {
  @media only screen and (max-width: 450px) {
    @content;
  }
}

@mixin buttonBreakpointTwo() {
  @media only screen and (max-width: 450px) {
    @content;
  }
}

@mixin overwriteMaterialBreakpointOne() {
  @media only screen and (max-width: 365px) {
    @content;
  }
}

// scss components
@mixin coArtikeltabelleBreakpoint() {
  @media only screen and (max-width: 800px) {
    @content;
  }
}

// scss components
@mixin coBestandstabelleBreakpoint() {
  @media only screen and (max-width: 800px) {
    @content;
  }
}

@mixin coNavigationDesktopBreakpoint() {
  @media only screen and (max-width: 396px) {
    @content;
  }
}

@mixin coNavigationMobileBreakpoint() {
  @media only screen and (max-width: 815px) {
    @content;
  }
}

@mixin coQualitaetssicherungBreakpointOne() {
  @media only screen and (max-width: 640px) {
    @content;
  }
}

@mixin coSucheBreakpointOne() {
  @media only screen and (max-width: 310px) {
    @content;
  }
}

@mixin materialGridSm() {
  @media only screen and (max-width: 599px) {
    @content;
  }
}

@mixin materialGridSmMin() {
  @media only screen and (min-width: 600px) {
    @content;
  }
}

@mixin materialGridMd() {
  @media only screen and (max-width: 959px) {
    @content;
  }
}

@mixin materialGridMdMin() {
  @media only screen and (min-width: 960px) {
    @content;
  }
}

@mixin materialGridLg() {
  @media only screen and (max-width: 1279px) {
    @content;
  }
}

@mixin materialGridLgMin() {
  @media only screen and (min-width: 1280px) {
    @content;
  }
}

@mixin cameraAndScannerBreakpointOne() {
  @media only screen and (max-width: 295px) {
    @content;
  }
}
