.coHome {
  &__logo {
    width: 400px;
    position: relative;
    height: 150px;
    background-position: bottom;
    background-size: contain;
    background-repeat: no-repeat;
    margin-top: -75px;
    opacity: 0.4;

    @include coArtikeltabelleBreakpoint() {
      margin-top: 0;
    }

    @include buttonsBreakpointOne() {
      width: 200px;
      margin-top: -50px;
    }
  }

  &__contentWrapper {
    margin-left: 0 !important;
    margin-right: 0 !important;
    width: 100% !important;
  }

  &__liveTrackerTable {
    width: 280px !important;
  }

  &__sectionIcon {
    width: 50px;
    cursor: pointer;
    transition: all 0.3s;
    position: relative;
    margin-bottom: 40px;
    display: block;

    @include materialGridMd() {
      display: inline-block;
      margin: 5px 10px 0px;
    }

    &:hover {
      -webkit-transform: rotate(5deg) scale(1.1); /* Chrome, Opera 15+, Safari 3.1+ */
      -ms-transform: rotate(5deg) scale(1.1); /* IE 9 */
      transform: rotate(5deg) scale(1.1); /* Firefox 16+, IE 10+, Opera */
    }
  }

  &__sectionIconBig {
    width: 120px;
    cursor: pointer;
    transition: all 0.3s;
    position: relative;
    display: inline-block;
    padding: 20px;
    margin: 0 20px;
    border: 2px solid;
    border-image: linear-gradient(45deg, $color-main, rgb(250, 250, 250), $color-main, rgb(250, 250, 250)) 1;

    &:hover {
      -webkit-transform: rotate(5deg) scale(1.1); /* Chrome, Opera 15+, Safari 3.1+ */
      -ms-transform: rotate(5deg) scale(1.1); /* IE 9 */
      transform: rotate(5deg) scale(1.1); /* Firefox 16+, IE 10+, Opera */
    }
  }
}
