.root {
  position: relative;
  overflow: hidden;
  min-height: 100vh;
}

.header {
  padding-top: 5rem;
}

.header h2 {
  font-weight: 600;
  font-size: 18px;
}

.introduction {
  margin-top: 3.5rem;
}

.introduction__header {
  font-weight: 600;
  font-size: 18px;
  margin: 0;
}

.introduction__content {
  font-size: 18px;
  font-weight: 400;
  color: rgb(26, 25, 25);
  line-height: 34.2px;
  margin: 0;
}

.content {
  max-width: 1100px;
  margin: 0 auto;
  padding: 0 36px;
  padding-bottom: 100px;
}

.section,
.section * {
  margin: 0;
  font-size: 16px;
  color: #888888;
  font-weight: 600;
}

.section {
  margin-top: 28px;
}

.section__contentItem {
  margin-top: 10px;
}
