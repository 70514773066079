.root {
  position: relative;
  overflow: hidden;
  min-height: 100vh;
}

.header {
  padding-bottom: 6.25rem;
  padding-top: 5rem;
}

.header__container {
  width: fit-content;
  padding: 0 12px;
  margin: 0 auto;
}

.header__container h1 {
  width: fit-content;
  text-align: center;
  font-size: 2.5rem;
  font-weight: 400;
}

.content {
  max-width: 1100px;
  margin: 0 auto;
  padding: 0 12px;
  padding-bottom: 100px;
}

.content .content__header {
  text-align: center;
}

.content .content__upper {
  padding-left: 24px;
  padding-right: 24px;
  display: flex;
  justify-content: space-between;
  padding-bottom: 30px;
  border-bottom: 1px solid rgb(26, 25, 25);
}

.content .content__bottom {
  padding-left: 24px;
  padding-right: 24px;
}

.infoBox__title {
  margin-bottom: 1.75rem;
  margin-top: 3.5rem;
  font-size: 1.74rem;
  line-height: 1.3;
  font-weight: 400;
}

.infoBox__content {
  font-size: 1.125rem;
  font-weight: 400;
  color: rgb(26, 25, 25);
  line-height: 34.2px;
}

.content__bottom .sectionTitle h2 {
  margin-bottom: 1.8rem;
  margin-top: 3.5rem;
  font-size: 1.74rem;
  line-height: 1.3;
  font-weight: 400;
}

.root ul {
  padding: 0;
  list-style: none;
}

.root ul:nth-child(2) {
  margin-top: 20px;
}

@media only screen and (max-width: 991px) {
  .header {
    padding-bottom: 5rem;
    padding-top: 4rem;
  }

  .header__container h1 {
    font-size: 2rem;
  }

  .content .content__upper {
    display: block !important;
  }

  .infoBox__title,
  .content__bottom .sectionTitle h2 {
    font-size: 1.5rem;
  }
}

@media only screen and (max-width: 767px) {
  .header {
    padding-bottom: 3rem;
    padding-top: 2rem;
  }

  .header__container h1 {
    font-size: 1.5rem;
  }

  .infoBox__title {
    margin-bottom: 1.25rem;
    margin-top: 3rem;
  }

  .infoBox__title,
  .content__bottom .sectionTitle h2 {
    font-size: 1.25rem;
  }

  .infoBox__content {
    font-size: 1rem;
  }
}

@media only screen and (max-width: 575px) {
  .content .content__upper,
  .content .content__bottom {
    padding-left: 0;
    padding-right: 0;
  }
}
