.coLandingPage {
  position: relative;
  overflow: hidden;
  min-height: 100vh;
}

.coLandingPage * {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

a {
  cursor: pointer;
}

.main__funktionenSection {
  padding: 100px;
}

.main__funktionenSection .contentWrapper {
  padding: 0 12px;
  margin: 0 auto;
  max-width: 1100px;
}

.main__funktionenSection .contentWrapper .container {
  display: flex;
  flex-wrap: wrap;
}

.main__funktionenSection:nth-child(odd) .contentWrapper .container {
  flex-direction: row-reverse;
}

.main__funktionenSection .textContainer {
  max-width: 50%;
  flex: 0.5;
  padding: 0 24px;
  color: #111;
}

.main__funktionenSection .textContainer.align__self--center {
  align-self: center;
}

.main__funktionenSection .textContainer h2 {
  margin-bottom: 24px;
  font-size: 2.25rem;
  line-height: 1.2;
  font-weight: 600;
}

.main__funktionenSection .textContainer ul {
  padding: 0;
  list-style-type: none;
  color: #5c626b;
  font-size: 19px;
}

.main__funktionenSection .textContainer ul li {
  border-bottom: 1px solid #ececec;
  padding: 0.75rem 1.875rem 0.75rem 0;
  position: relative;
}

.main__funktionenSection .textContainer ul li::after {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9IiM0Q0FFNTAiIHZpZXdCb3g9IjAgMCAyNCAyNCI+PHBhdGggZmlsbD0ibm9uZSIgZD0iTTAgMGgyNHYyNEgweiIvPjxwYXRoIGQ9Ik05IDE2LjE3IDQuODMgMTJsLTEuNDIgMS40MUw5IDE5IDIxIDdsLTEuNDEtMS40MXoiLz48L3N2Zz4=);
  background-position: 50%;
  background-size: 24px auto;
  content: '';
  display: block;
  font-size: 0;
  height: 24px;
  margin-top: -12px;
  position: absolute;
  right: 0;
  top: 50%;
  width: 24px;
}

.main__funktionenSection .imageContainer {
  max-width: 50%;
  flex: 0.5;
}

.main__funktionenSection:nth-child(odd) .imageContainer {
  padding: 0 24px 0 56px;
}

.main__funktionenSection:nth-child(even) .imageContainer {
  padding: 0 56px 0 24px;
}

.main__funktionenSection .imageContainer > img {
  width: 100%;
}

@media only screen and (max-width: 1160px) {
  .main__funktionenSection .textContainer h2 {
    font-size: 1.5rem;
  }
}

@media only screen and (max-width: 900px) {
  .main__funktionenSection {
    padding: 75px 0;
  }

  .main__funktionenSection .container {
    justify-content: center;
  }

  .main__funktionenSection .textContainer {
    flex: auto;
    max-width: 100%;
    padding: 0 24px;
  }

  .main__funktionenSection .imageContainer {
    flex: auto;
    max-width: 60%;
    padding: 0 24px 30px !important;
  }
}

@media only screen and (max-width: 650px) {
  .main__funktionenSection .imageContainer {
    max-width: 80%;
  }

  .main__preiseSection .paket {
    height: fit-content !important;
  }

  .main__preiseSection .paket2 .paket__containerBody .topFunktionen {
    height: auto !important;
  }

  .main__preiseSection .paket2 .paket__containerBody .einmaligKosten .kostenPerMonat {
    height: auto !important;
  }

  .main__preiseSection .paket2 .paket__containerBody .addOn {
    height: auto !important;
  }

  .main__preiseSection .preiseSection__containerBottomWrapper {
    flex-direction: column;
  }
  .main__preiseSection .paket2 {
    flex: 1 !important;
    max-width: 100% !important;
  }

  .main__preiseSection .paket2:nth-child(2) {
    margin-top: 40px;
  }
}

@media only screen and (max-width: 420px) {
  .main__funktionenSection .textContainer {
    padding: 0;
  }

  .main__funktionenSection .textContainer h2 {
    font-size: 1.2rem;
  }

  .main__funktionenSection .textContainer ul {
    font-size: 16px;
  }

  .main__funktionenSection .imageContainer {
    max-width: 100%;
  }
}

.arrowUp {
  font-weight: 500;
  transition: 0.3s linear;
  position: fixed;
  width: 44px;
  height: 44px;
  z-index: 999;
  text-align: center;
  border-radius: 50%;
  right: 20px;
  bottom: 80px;
  color: #fff;
  background-image: linear-gradient(#4d90e1, #417edc);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  opacity: 0;
  visibility: hidden;
}

.arrowUp.active {
  opacity: 1;
  visibility: visible;
}

.arrowUp span svg {
  width: 17px;
}

.main__header {
  background-image: url('https://traceport.de/assets/images/tracporthome/home_main_header_background.png');
  height: auto;
  max-width: 100%;
  color: #fff;
  background-position: 65% 50%;
  background-repeat: no-repeat;
  background-size: cover;
}

.main__headerContainer {
  margin-left: auto;
  margin-right: auto;
  padding-left: 12px;
  padding-right: 12px;
  max-width: 1100px;
  display: flex;
  min-height: 762px;
  position: relative;
  height: 75vh;
  max-height: 1000px;
}

.main__headerContainerInfo {
  align-self: center;
  margin: 4rem 0;
  padding: 0 15px;
  max-width: 50%;
  color: white;
}

.main__headerContainerInfo .top {
  margin: 0 0 1rem;
  letter-spacing: -0.4px;
  margin-bottom: 10px;
  padding-bottom: 15px;
  font-size: 2.5rem;
  font-weight: 700;
  line-height: 1.3;
}

.main__headerContainerInfo .middle > p {
  margin: 0 0 1rem;
  line-height: 1.7;
  font-size: 17px;
  font-weight: 600;
}

.main__headerContainerInfo .middle ul {
  line-height: 1.5;
  list-style: none;
  margin: 0 0 1.25rem;
  padding: 0;
  font-size: 16px;
  font-weight: 400;
  margin-top: 20px;
}

.main__headerContainerInfo li {
  line-height: 1.5;
  list-style: none;
  font-size: 16px;
  padding: 0.25rem 0 0.25rem 1.875rem;
  position: relative;
}

.main__headerContainerInfo li:before {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9IiM0Q0FFNTAiIHZpZXdCb3g9IjAgMCAyNCAyNCI+PHBhdGggZmlsbD0ibm9uZSIgZD0iTTAgMGgyNHYyNEgweiIvPjxwYXRoIGQ9Ik05IDE2LjE3IDQuODMgMTJsLTEuNDIgMS40MUw5IDE5IDIxIDdsLTEuNDEtMS40MXoiLz48L3N2Zz4=);
  background-position: 50%;
  background-size: 24px auto;
  content: '';
  display: block;
  font-size: 0;
  height: 24px;
  left: 0;
  margin-top: -12px;
  position: absolute;
  top: 50%;
  width: 24px;
}

.main__headerContainerInfo .bottom {
  margin: 24px 0 32px;
  padding-top: 8px;
}

.main__headerContainerInfo .bottom .kostenlosTestenLink > button {
  padding: 16px 40px;
  font-size: 1.2rem;
  border-radius: 4px;
  font-weight: 600;
  transition: all 0.15s;
  user-select: none;
  display: block;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.08), 0 1px 3px 0 rgba(0, 0, 0, 0.06);
  text-decoration: none;
  margin: 0 16px 16px 0;
  width: fit-content;
  border: none;
  cursor: pointer;
  color: #fff;
  background-image: linear-gradient(#4d90e1, #417edc);
  margin-left: 32px;
}

.main__headerContainerInfo .bottom .kostenlosTestenLink > button:hover {
  transform: translateY(-1px);
  background-image: linear-gradient(#5b99e3, #5088df);
}

.main__headerContainerInfo .bottom .kostenlosTestenLink > button:active {
  transform: none;
}

.main__headerContainerInfo .bottom .loginLink > a:hover {
  transform: translateY(-1px);
}

.main__headerContainerInfo .bottom .formContainer {
  display: none;
}

.main__headerContainerInfo .bottom .formContainer .inputContainer {
  position: relative;
}

.main__headerContainerInfo .bottom .formContainer .inputContainer > input {
  width: 100%;
  border-radius: 8px;
  outline: none;
  font-size: 16px;
  padding: 16px 178px 16px 18px;
  transition: all 0.3s;
  border: 2px solid transparent;
  border-bottom: none;
}

.main__headerContainerInfo .bottom .formContainer .inputContainer > input.kostenlosTestenEmail--invalid {
  border-color: red;
}

.main__headerContainerInfo .bottom .formContainer .inputContainer > .circularProgress {
  position: absolute !important;
  top: 7px;
  left: 8px;
  display: none;
}

.main__headerContainerInfo .bottom .formContainer .inputContainer > .circularProgress--active {
  display: block;
}

.main__headerContainerInfo .bottom .formContainer .inputContainer > .kostenlosTestenErrorInputMessage {
  display: block;
  font-size: 13px;
  font-weight: 600;
  line-height: 1.1;
  padding: 0.5rem 1rem;
  transition: all 0.3s;
  visibility: hidden;
  opacity: 0;
}

.main__headerContainerInfo .bottom .formContainer .inputContainer > .kostenlosTestenErrorInputMessage--active {
  visibility: visible;
  opacity: 1;
  display: block;
}

.main__headerContainerInfo .bottom .formContainer .inputContainer .kostenlosTestenLink {
  margin: 0;
  position: absolute;
  top: 13%;
  right: 18px;
}

.main__headerContainerInfo .bottom .formContainer .inputContainer .kostenlosTestenLink > button {
  padding: 7px 16px;
  font-size: 14px;
  margin: 0;
}

.main__headerContainerInfo .formContainer label {
  opacity: 0;
  margin-top: 10px;
  display: block;
}

.main__headerContainerInfo .agb {
  display: none;
  font-size: 12px;
  color: inherit !important;
  opacity: 0.8;
}

.main__headerContainerInfo .agb > a {
  line-height: 1.15;
  background-color: transparent;
  text-decoration: none;
  cursor: pointer;
  border-bottom: 1px solid;
  color: #fff;
  transition: all 0.15s;
}

.main__headerContainerInfo .agb > a:hover {
  border-bottom: 1px solid transparent;
}

.main__headerContainerInfo .bottomImage {
  display: none;
  margin: 22px 0;
}

.main__headerContainerInfo .bottomImage img {
  width: 365px;
}

@media only screen and (max-width: 1000px) {
  .main__header {
    background-image: none;
    background-color: #173e5e;
  }

  .main__headerContainerInfo {
    color: white;
    align-self: center;
    max-width: 65%;
  }

  .main__headerContainerInfo .top {
    font-weight: 900;
    font-size: 25px;
  }

  .main__headerContainerInfo .bottom .buttons {
    display: none;
  }

  .main__headerContainerInfo .bottom .formContainer {
    display: block;
  }

  .main__headerContainerInfo .agb {
    display: block;
  }
}

@media only screen and (max-width: 770px) {
  .main__headerContainer {
    min-height: 900px;
  }
  .main__headerContainerInfo .bottom {
    margin-bottom: 0;
  }

  .main__headerContainerInfo .bottom .formContainer .inputContainer > input {
    padding: 16px 18px 16px 18px;
  }

  .main__headerContainerInfo .bottom .formContainer .inputContainer > .kostenlosTestenErrorInputMessage {
    display: none;
  }

  .main__headerContainerInfo .bottom .formContainer .inputContainer > .kostenlosTestenErrorInputMessage--active {
    display: block !important;
  }

  .main__headerContainerInfo .bottom .formContainer .inputContainer .kostenlosTestenLink {
    position: static;
    transform: none;
    margin-top: 10px;
  }

  .main__headerContainerInfo .bottom .formContainer .inputContainer .kostenlosTestenLink > button {
    width: 100%;
    padding: 10px 20px;
    font-size: 1rem;
  }

  .main__headerContainerInfo .agb {
    margin-top: 50px;
  }
}

@media only screen and (max-width: 569px) {
  .main__headerContainer {
    min-height: 1100px;
  }

  .main__headerContainerInfo {
    max-width: 100%;
  }

  .main__headerContainerInfo * {
    text-align: center;
  }

  .main__headerContainerInfo .top {
    margin-bottom: 16px;
    padding-bottom: 0;
  }

  .main__headerContainerInfo .bottomImage {
    display: block;
  }
}

@media only screen and (max-width: 412px) {
  .main__headerContainer {
    padding: 0;
  }
}

@media only screen and (max-width: 380px) {
  .main__headerContainerInfo {
    padding: 0 3px;
  }
}

@media only screen and (max-width: 370px) {
  .main__headerContainer {
    min-height: 900px;
  }

  .main__headerContainerInfo {
    align-self: unset;
  }

  .main__headerContainerInfo .bottomImage {
    display: none;
  }
}

.main__kontaktSection {
  padding-bottom: 150px;
}
.kontakt__header {
  background-image: url('https://traceport.de/assets/images/tracporthome/home_main_preise/kostenuebersicht.png');
  background-size: cover;
  background-position: center;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.kontakt__header h1 {
  text-align: center;
  color: white;
  font-weight: 500;
  font-size: 2.5rem;
}

.kontakt__content {
  padding: 0 12px;
}

.kontakt__contentAdress {
  display: flex;
  background-color: #4d90e1;
  margin: 0 auto;
  padding: 13px 0;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  font-size: 19px;
  font-weight: 500;
  color: white;
}

.kontakt__contentAdress img {
  width: 20px;
}

.kontakt__contentAdress .text {
  display: flex;
}

.kontakt__contentAdress .text span {
  margin-left: 10px;
  font-size: 14px;
}

.kontakt__contentPerson {
  margin: 80px auto 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 600px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.08);
  transition: all 0.15s;
}

.kontakt__contentPerson:hover {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.08), 0 6px 6px rgba(0, 0, 0, 0.11);
  -webkit-transform: translateY(-1px);
  transform: translateY(-1px);
}

.kontakt__contentPerson .top {
  color: rgb(92, 98, 107);
  display: flex;
}

.kontakt__contentPerson .top .imageContainer {
  padding: 12px;
}

.kontakt__contentPerson img {
  max-width: 100%;
}

.kontakt__contentPerson .top .text {
  font-size: 18px;
  padding-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px 12px 25px 20px;
  min-height: 234px;
}

.kontakt__contentPerson .top .text h3 {
  font-weight: 600;
}

.kontakt__contentPerson .text a {
  text-decoration: none;
  color: rgb(67, 170, 101);
}

.kontakt__contentMessage {
  font-size: 19px;
  font-weight: 500;
  max-width: 800px;
  text-align: center;
  margin-top: 80px;
}

.kontakt__contentPerson .bottom {
  display: flex;
  width: 100%;
  padding: 10px 12px;
  border-radius: 2px;
  background-color: #4d90e1;
}

.kontakt__contentPerson .bottom span {
  color: #fff;
  font-weight: 500;
  margin-right: 20px;
}

.kontakt__contentPerson .bottom span:nth-child(2) {
  margin-left: 30px;
}

.kontakt__contentPerson .bottom a {
  font-weight: 500;
  text-decoration: none;
  color: #fff;
  border-bottom: 1px solid #fff;
  transition: all 0.3s;
}

.kontakt__contentPerson .bottom a:hover {
  border-color: transparent;
}

@media only screen and (max-width: 767px) {
  .kontakt__contentAdress img {
    width: 40px;
  }

  .kontakt__contentAdress .text {
    flex-direction: column;
  }
}

@media only screen and (max-width: 575px) {
  .kontakt__contentPerson .top img {
    width: 200px;
  }

  .kontakt__contentPerson .top {
    flex-direction: column;
    align-items: center;
  }

  .kontakt__contentPerson .top .text {
    text-align: center;
  }

  .kontakt__contentPerson .bottom {
    width: auto;
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 515px) {
  .kontakt__contentPerson .top .text {
    min-height: 320px;
  }

  .kontakt__contentPerson .bottom {
    flex-direction: column;
  }

  .kontakt__contentPerson .bottom span {
    margin-left: 0 !important;
  }

  .kontakt__contentPerson .bottom span:nth-child(2) {
    margin-top: 10px;
  }
}

@media only screen and (max-width: 340px) {
  .kontakt__contentAdress img {
    width: 30px;
  }

  .kontakt__contentAdress .text span {
    font-size: 12px;
    padding-right: 3px;
    margin-left: 0;
  }

  .kontakt__contentPerson .bottom {
    font-size: 12px;
  }
}

.main__preiseSection {
  margin: 100px 0.5rem 100px;
}

.main__preiseSection hr {
  border: 0;
  border-top: 1px solid #ececec;
  height: 0;
  margin-bottom: 1rem;
  margin-top: 1rem;
  box-sizing: content-box;
  overflow: visible;
}

.main__preiseSectionHeader {
  background-image: url('https://traceport.de/assets/images/tracporthome/home_main_preise/kostenuebersicht.png');
  background-size: cover;
  background-position: center;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.main__preiseSectionHeader h1 {
  text-align: center;
  color: white;
  font-weight: 500;
  font-size: 2.5rem;
}

.main__preiseSection .preiseSection__container {
  max-width: 1100px;
  margin: 100px auto 0;
  padding: 0 12px;
}

.main__preiseSection .preiseSection__containerTop .container {
  display: flex;
  justify-content: center;
}

.main__preiseSection .preiseSection__containerTop .container button {
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
  background-color: transparent;
  border: 1px solid #e5e5e5;
  color: #5c626b;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.2;
  margin-left: -1px;
  outline: 0;
  padding: 9px 16px;
  position: relative;
  transition: all 0.15s;
  user-select: none;
}

.main__preiseSection .preiseSection__containerTop .container button:hover {
  background-color: rgba(255, 255, 255, 0.8);
  border-color: #4a89dc;
  color: #4a89dc;
  z-index: 5;
}

.main__preiseSection .preiseSection__containerTop .container button.active {
  background-color: rgba(255, 255, 255, 0.8);
  border-color: #4a89dc;
  color: #4a89dc;
  z-index: 5;
}

.main__preiseSection .preiseSection__containerBottom {
  margin-left: -12px;
  margin-right: -12px;
  padding: 0 0 1.5rem;
}

.main__preiseSection .preiseSection__containerBottomWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.main__preiseSection .paket {
  padding-left: 12px;
  padding-right: 12px;
  position: relative;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.08);
  transition: all 0.15s;
  margin: 0 0.5rem;
  min-width: 274px;
}

.main__preiseSection .paket:hover {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.08), 0 6px 6px rgba(0, 0, 0, 0.11);
  -webkit-transform: translateY(-1px);
  transform: translateY(-1px);
}

.main__preiseSection .paket__container {
  margin-bottom: 2rem;
  padding-top: 4rem;
}

.main__preiseSection .paket__containerTop {
  height: 100px;
  padding: 0 1rem;
  margin-bottom: 2.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  text-align: left;
}

// paket nr.4

.main__preiseSection .paket2 {
  flex: 0.3;
  max-width: 32%;
}

.main__preiseSection .paket2 .paket__container {
  padding-top: 20px;
}

.main__preiseSection .paket2 .paket__containerTop {
  height: auto;
  margin-bottom: 20px;
}

.main__preiseSection .paket2 .paket__containerTop h2 {
  text-align: center;
}

.main__preiseSection .paket2 .paket__containerTop > span {
  text-align: center;
  margin: 0;
}

.main__preiseSection .paket2 .paket__einmaligKosten {
  height: auto;
  min-height: auto;
}

.main__preiseSection .paket2 .paket__einmaligKostenValue {
  color: black;
  display: flex;
  justify-content: space-between;
}

.main__preiseSection .paket2 .einmaligKostenValue2 {
  padding-left: 8px;
  strong {
    white-space: nowrap;
  }
}

.main__preiseSection .paket2 .paket__containerBody {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.main__preiseSection .paket2 .paket__containerBody > div {
  padding: 0 12px;
  margin-top: 16px;
}

.main__preiseSection .paket2 .paket__containerBody .topFunktionen {
  height: 325.6px;
}

.main__preiseSection .paket2 .paket__containerBody .topFunktionen span,
.main__preiseSection .paket2 .paket__containerBody .addOn span.title,
.main__preiseSection .paket2 .paket__containerBody .addOn span.subTitle {
  padding-top: 0;
  margin-bottom: 1rem !important;
  color: #5c626b;
  display: block;
  font-weight: 600;
}

.main__preiseSection .paket2 .paket__containerBody .addOn span.subTitle {
  margin-bottom: 0.8rem !important;
  font-size: 12px;
}

.main__preiseSection .paket2 .paket__containerBody .einmaligKosten .einmaligKostenWrapper {
  font-weight: 500;
  background-color: #4caf50;
  color: #fff;
  border-radius: 5px;
  padding: 8px;
  height: fit-content;
  margin-bottom: 20px;
}

.main__preiseSection .paket2 .paket__containerBody .einmaligKosten .kostenPerMonat {
  display: block;
  margin-bottom: 20px;
  height: 74.32px;
}

.main__preiseSection .paket2 .paket__containerBody .einmaligKosten .kostenPerMonat strong {
  font-size: 1.3rem;
  font-weight: 700;
}

.main__preiseSection .paket2 .paket__containerBody .einmaligKosten .einmaligKostenTitle {
  margin-top: 0px;
  font-weight: 600;
}

.main__preiseSection .paket2 .paket__containerBody .addOn {
  height: 317.16px;
}

.main__preiseSection .paket2 .paket__containerBody .addOn ul {
  min-height: auto !important;
}

.main__preiseSection .paket2 .paket__containerBody .addOn ul li::before {
  content: '+' !important;
  font-weight: 600;
  color: rgb(67, 170, 101);
  font-size: larger;
  position: absolute;
  top: -3px;
  left: 0;
}

///////////////////////////////////////////

.customDiv {
  margin-top: 40px;
}

.customDiv ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  color: #5c626b;
  padding-left: 20px;
}

.customDiv ul li {
  margin-bottom: 0.5rem;
  font-size: 14px;
  line-height: 1.5;
  white-space: nowrap;
}

.customDiv ul li::before {
  content: '\2022';
  color: #4f88d7;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
  position: absolute;
}

.customDiv h5 {
  margin-top: 0;
  color: #5c626b;
  font-weight: 500;
}

.customDiv h6 {
  padding-top: 0.5rem !important;
  margin-bottom: 1rem !important;
  color: #5c626b;
  font-weight: 500;
}

.main__preiseSection .paket__containerTop h2 {
  color: rgb(79, 136, 215);
  font-size: 1.5rem;
  line-height: 1.4;
  font-weight: 600;
  margin: 0 0 0.5rem;
  padding-top: 0.5rem;
}

.main__preiseSection .paket__containerTop h4 {
  color: rgb(79, 136, 215);
  line-height: 1.4;
  font-weight: 600;
  text-align: center;
  padding-top: 0.5rem;
  margin: 0;
}

.main__preiseSection .paket__containerTop span {
  font-size: 1.3rem;
  font-weight: 400;
  margin-right: 5px;
}

.main__preiseSection .paket__perMonat {
  font-size: 1.4rem !important;
  font-weight: 300 !important;
  margin-right: 5px !important;
}

.main__preiseSection .paket__perMonatNachAnfrage {
  font-size: 1rem !important;
}

.main__preiseSection .paket__support {
  display: block;
  font-size: 0.75rem !important;
  margin-top: 5px;
}

.main__preiseSection .paket__einmaligKosten {
  margin-top: 20px;
  font-weight: 500;
  background-color: #4caf50;
  color: #fff;
  border-radius: 5px;
  padding: 8px;
  height: 80px;
  min-height: 80px;
}

.main__preiseSection .paket__einmaligKosten p {
  margin: 0;
}

.main__preiseSection .paket__einmaligKosten span {
  display: block;
  margin-top: 10px;
  font-size: 12px !important;
}

.main__preiseSection .paket__containerBottom {
  padding: 1rem 1.25rem 1.25rem;
}

.main__preiseSection .paket__containerBottom span {
  padding-top: 0.5rem !important;
  margin-bottom: 1rem !important;
  color: #5c626b;
  display: block;
  font-weight: 600;
}

.main__preiseSection .paket__containerBottom ul,
.main__preiseSection .paket2 .paket__containerBody .topFunktionen ul,
.main__preiseSection .paket2 .paket__containerBody .addOn ul {
  color: #5c626b;
  list-style: none;
  margin: 0;
  padding-left: 0;
  margin-bottom: 1.5rem !important;
}

.main__preiseSection .paket__containerBottom ul li,
.main__preiseSection .paket2 .paket__containerBody .topFunktionen ul li,
.main__preiseSection .paket2 .paket__containerBody .addOn ul li {
  font-size: 14px;
  line-height: 1.6;
  padding-left: 28px;
  margin-bottom: 0.5rem;
  position: relative;
}

.blueBold {
  color: #4f88d7;
  font-weight: 700;
}

.main__preiseSection .paket__containerBottom ul li::before,
.main__preiseSection .paket2 .paket__containerBody .topFunktionen ul li::before {
  background-image: url(data:image/svg+xml;base64,PHN2ZyBmaWxsPSIjNENBRTUwIiBoZWlnaHQ9IjI0IiB2aWV3Qm94PSIwIDAgMjQgMjQiIHdpZHRoPSIyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICAgIDxwYXRoIGQ9Ik0wIDBoMjR2MjRIMHoiIGZpbGw9Im5vbmUiLz4KICAgIDxwYXRoIGQ9Ik05IDE2LjE3TDQuODMgMTJsLTEuNDIgMS40MUw5IDE5IDIxIDdsLTEuNDEtMS40MXoiLz4KPC9zdmc+Cg==);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: 18px auto;
  content: '';
  display: inline-block;
  height: 20px;
  left: 0;
  position: absolute;
  top: 1px;
  width: 20px;
}

.main__preiseSection .paket__containerBottom .kostenlosTestenLink,
.main__preiseSection .paket2 .paket__containerBody .kostenlosTestenLink {
  padding-bottom: 0.5rem !important;
  padding-top: 0.5rem !important;
  text-align: center;
}

.main__preiseSection .paket__containerBottom .kostenlosTestenLink button,
.main__preiseSection .paket2 .paket__containerBody .kostenlosTestenLink button {
  padding: 12px 20px;
  font-size: 1rem;
  border-radius: 4px;
  font-weight: 600;
  transition: all 0.15s;
  user-select: none;
  display: block;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.08), 0 1px 3px 0 rgba(0, 0, 0, 0.06);
  text-decoration: none;
  margin: 0 auto;
  border: none;
  cursor: pointer;
  color: #fff;
  background-image: linear-gradient(#4d90e1, #417edc);
}

.main__preiseSection .paket__containerBottom .kostenlosTestenLink button:hover,
.main__preiseSection .paket2 .paket__containerBody .kostenlosTestenLink button:hover {
  transform: translateY(-1px);
  background-image: linear-gradient(#5b99e3, #5088df);
}

.main__preiseSection .paket__containerBottom .kostenlosTestenLink button:active,
.main__preiseSection .paket2 .paket__containerBody .kostenlosTestenLink button:active {
  transform: none;
}

.main__preiseSection .marker__container > p {
  line-height: 1.7;
  margin: 0 0 1rem;
  text-align: center;
  font-size: 14px;
  color: #5c626b;
}

.main__preiseSection .customPaket {
  padding-left: 12px;
  padding-right: 12px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.08);
  transition: all 0.15s;
  margin: 4rem auto 0;
  min-width: 274px;
  width: 50%;
  max-width: unset;
}

.main__preiseSection .customPaket .paket__container {
  padding-top: 0;
  margin-bottom: 0;
  padding-bottom: 1rem;
}

.main__preiseSection .customPaket .paket__containerTop {
  height: auto;
  text-align: center;
}

.main__preiseSection .customPaket .paket__containerTop span p {
  margin-top: 20px;
}

@media only screen and (max-width: 991px) {
  .main__preiseSection .paket__containerTop h2 {
    font-size: 1.25rem;
    line-height: 1.5;
  }

  .main__preiseSection .paket__perMonat {
    font-size: 1.188rem !important;
    line-height: 1.5 !important;
  }

  .main__preiseSection .paket__containerBottom ul {
    min-height: 140px;
  }

  .main__preiseSection .paket__containerBottom ul li {
    font-size: 13px;
    line-height: 1.5;
  }
}

@media only screen and (max-width: 610px) {
  .main__preiseSection .paket {
    max-width: 100%;
    flex: 1;
  }

  .main__preiseSection .paket__einmaligKosten {
    height: auto;
    min-height: auto;
  }

  .main__preiseSection .paket__einmaligKosten > p {
    display: inline-block;
  }

  .main__preiseSection .paket__einmaligKosten > span {
    display: inline-block;
    margin-left: 20px;
  }

  .main__preiseSection .customPaket {
    width: 100%;
  }
}

@media only screen and (max-width: 322px) {
  .main__preiseSection .preiseSection__container {
    padding: 0 3px;
  }
}
