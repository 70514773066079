.accordion {
  box-shadow: none !important;
  border-bottom: 1px solid $borderColor !important;
  background-color: $accordionBackgroundColor !important;

  &__summary {
    &:hover {
      background-color: $accordionSummaryHoverColor !important;
    }
  }
}
