.coSignIn {
  &__center {
    position: absolute !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__link {
    text-align: center;
    padding: 10px !important;
  }
  &__serverStatueIcon {
    margin-top: 7%;
    margin-left: 70%;
  }
}
