.root {
  position: relative;
  overflow: hidden;
  min-height: 100vh;
}

.root p {
  margin: 15px 0;
}

.root a,
.root a:visited {
  color: black;
}

.root b {
  font-weight: 500;
  margin-top: 15px;
}

.root ul {
  line-height: 1.5;
  list-style: none;
  margin: 0 0 1.25rem;
  padding: 0;
  font-size: 16px;
  font-weight: 400;
  margin-top: 20px;
}

.root li {
  line-height: 1.5;
  list-style: none;
  font-size: 16px;
  padding: 0.25rem 0 0.25rem 1.875rem;
  position: relative;
}

.root li:before {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9IiM0Q0FFNTAiIHZpZXdCb3g9IjAgMCAyNCAyNCI+PHBhdGggZmlsbD0ibm9uZSIgZD0iTTAgMGgyNHYyNEgweiIvPjxwYXRoIGQ9Ik05IDE2LjE3IDQuODMgMTJsLTEuNDIgMS40MUw5IDE5IDIxIDdsLTEuNDEtMS40MXoiLz48L3N2Zz4=);
  background-position: 50%;
  background-size: 24px auto;
  content: '';
  display: block;
  font-size: 0;
  height: 24px;
  left: 0;
  margin-top: -12px;
  position: absolute;
  top: 50%;
  width: 24px;
}

.header {
  padding-bottom: 6.25rem;
  padding-top: 5rem;
}

.header__container {
  width: 36%;
  padding: 0 12px;
  margin: 0 auto;
}

.header__container h1 {
  width: fit-content;
  text-align: center;
  font-size: 2.5rem;
  font-weight: 400;
}

.content {
  max-width: 1100px;
  margin: 0 auto;
  padding: 0 36px;
  padding-bottom: 100px;
}

.infoBox__title {
  margin-bottom: 1.75rem;
  margin-top: 3.5rem;
  font-size: 1.74rem;
  line-height: 1.3;
  font-weight: 400;
}

.infoBox__content {
  font-size: 1.125rem;
  font-weight: 400;
  color: rgb(26, 25, 25);
  line-height: 34.2px;
}

.childrenNoMargin {
  margin: 15px 0;
}

.childrenNoMargin p {
  margin: 0;
}

.noMarginBottom {
  margin-bottom: 0;
}
.noMarginTop {
  margin-top: 0;
}

@media only screen and (max-width: 991px) {
  .header {
    padding-bottom: 5rem;
    padding-top: 4rem;
  }

  .header__container {
    width: 100%;
  }

  .header__container h1 {
    font-size: 2rem;
  }

  .infoBox__title {
    font-size: 1.5rem;
  }
}

@media only screen and (max-width: 767px) {
  .header {
    padding-bottom: 3rem;
    padding-top: 2rem;
  }

  .header__container h1 {
    font-size: 1.5rem;
  }

  .infoBox__title {
    margin-bottom: 1.25rem;
    margin-top: 3rem;
  }

  .infoBox__title {
    font-size: 1.25rem;
  }

  .infoBox__content {
    font-size: 1rem;
  }
}

@media only screen and (max-width: 575px) {
  .content {
    padding-left: 12px;
    padding-right: 12px;
  }
}
