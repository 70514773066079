.images {
  &--disabled {
    background-color: $color-black;
    opacity: 0.5;
  }

  &__fullWidthPreview {
    width: 100%;
  }

  &__gallery {
    cursor: pointer;
    margin-top: 20px !important;
    width: auto;
    max-width: 100% !important;
    max-height: 300px !important;

    transition: all 0.3s !important;
    &:hover {
      box-shadow: 0 0 11px $shadowOnHoverColor;
    }
  }

  &__headerIcon {
    width: 48px !important;
    height: auto !important;
  }

  &__textIcon {
    max-width: 60px !important;
    vertical-align: middle;
    margin-right: 10px !important;
  }

  &__trackingIcon {
    padding: 10px;
    width: 80px !important;
    height: auto !important;
    border-radius: 50%;
    display: block !important;

    @include imagesBreakpointOne {
      width: 70px !important;
    }

    @include imagesBreakpointTwo {
      width: 50px !important;
      padding: 5px;
    }

    @include imagesBreakpointThree {
      padding: 5px;
      width: 13vw !important;
    }

    &--done {
      background-color: $trackinIconDone;
    }

    &--inProgress {
      background-color: $trackinIconInProgress;
    }
  }
}
