// import -> resources
@import './resources/scss/colors.scss';
@import './resources/scss/fonts.scss';
@import './resources/scss/responsiveness.scss';
@import './resources/scss/buttons.scss';
@import './resources/scss/container.scss';
@import './resources/scss/images.scss';
@import './resources/scss/miscellaneous.scss';
@import './resources/scss/table.scss';
@import './resources/scss/typography.scss';
@import './resources/scss/overwriteMaterial.scss';
@import './resources/scss/dialogs.scss';
@import './resources/scss/cameraAndScanner.scss';
@import './resources/scss/scrollbar.scss';
@import './resources/scss/overwritePdfViewer.scss';
@import './resources/scss/icons.scss';
@import './resources/scss/accordion.scss';

// import -> components
@import './components/Navigation/CoNavigation.scss';
@import './components/SignIn/CoSignIn.scss';
@import './components/SignUp/CoSignUp.scss';
@import './components/Tracking/CoTracking.scss';
@import './components/Warenannahmelieferung/CoWarenannahmelieferung.scss';
@import './components/Wareneinganglieferschein/CoWareneinganglieferschein.scss';
@import './components/Versandlieferung/CoVersandlieferung.scss';
@import './components/Lagerverwaltungumlagerung/CoLagerverwaltungumlagerung.scss';
@import './components/Historie/CoHistorie.scss';
@import './components/Auftragsverwaltungauftrag/CoAuftragsverwaltungauftrag.scss';
@import './components/Versandavisierung/CoVersandavisierung.scss';
@import './components/Einstellungen/CoEinstellungen.scss';
@import './components/Artikelverwaltungartikel/coArtikelverwaltungartikel.scss';
@import './components/Common/Components/Artikel/CoArtikel.scss';
@import './components/LiveTracking/CoLiveTracking.scss';
@import './components/Home/CoHome.scss';
@import './components/Einstellungen/CoEinstellungen.scss';

.app {
  &__contentDesktop {
    padding-top: 127px !important;

    @include materialGridLg {
      padding-top: 176px !important;
    }

    @include materialGridMd {
      padding-top: 229px !important;
    }
    @include materialGridSm {
      padding-top: 431px !important;
    }

    @include coNavigationDesktopBreakpoint {
      padding-top: 482px !important;
    }
  }

  &__contentMobile {
    padding-top: 64px;

    @include coNavigationMobileBreakpoint {
      padding-top: 127px;
    }
  }

  &__contentSignIn {
    padding-top: 50px !important;
  }
}

html,
body,
#root,
.app__boxContent {
  height: 100%;
}

// for coLandingPage Header
html.noScroll {
  width: calc(100% - 15px);
  overflow: hidden;
}
