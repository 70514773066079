.coVersandlieferung {
  &__button {
    margin-top: 10px !important;
  }

  &__formControl {
    padding-top: 10px !important;
    width: fit-content !important;
  }

  &__formLabel {
    margin-bottom: 8px !important;
  }

  &__grid--formSection {
    margin-top: 10px !important;
    border-top: 1px solid $borderColor !important;
  }

  &__iconButton {
    color: $buttonColorGreen !important;
  }

  &__icon {
    font-size: 30px !important;
  }

  &__tableContainer {
    max-width: 900px !important;
    height: 200px !important;
    border-bottom: 1px solid $borderColor !important;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;

    &--disable {
      user-select: none;
      opacity: 0.5 !important;
      pointer-events: none !important;
    }
  }
  &__auftraegeTableContainer {
    height: 100px !important;
    background-color: $tableContainerBackgroundColor !important;
    border-top: none !important;
  }

  &__auftraegeTableCell {
    cursor: pointer;
    transition: all 0.3s;
    &:hover {
      background-color: $tableRowHoverColor !important;
    }
  }

  &__tableTitle {
    max-width: 900px !important;
    background-color: inherit !important;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    &--disable {
      user-select: none;
      opacity: 0.5 !important;
    }
  }

  &__textField {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
}
